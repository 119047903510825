import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../control/button";
import { chains } from "../../App";

interface NetworkWarningProps {
  preferredNetwork: number;
  forceDisplay?: boolean;
  showPopup?: boolean;
}

export default function NetworkWarning({
  preferredNetwork,
  forceDisplay,
}: NetworkWarningProps) {
  const [showWarning, setShowWarning] = useState(false);
  const { isConnected, chainId } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("forceDisplay", forceDisplay);
    if (forceDisplay !== undefined) {
      setShowWarning(forceDisplay);
    } else {
      let network = Number(preferredNetwork);
      console.log("network", network);
      if (isConnected && chainId !== network) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [isConnected, preferredNetwork, chainId, forceDisplay]);

  return (
    <div
      className={`fixed  left-0 w-full h-full flex items-center justify-center z-[50] transition-all ease-in-out duration-200  ${
        showWarning
          ? "translate-y-0 top-0  opacity-100"
          : "-translate-y-full -top-full opacity-0"
      }`}
    >
      <div
        className="bg-blackOpaque fixed top-0 left-0 w-full h-full block cursor-pointer"
        onClick={() => setShowWarning(false)}
      ></div>
      <div
        className={`w-4/5 max-w-[480px] bg-contentBox px-6 py-8  rounded-3xl text-center z-20 flex items-center flex-col`}
      >
        <h2 className="text-white font-semibold text-2xl">Warning</h2>
        <p className="w-4/5 mt-2 opacity-50 mx-auto mb-6">
          Your wallet is connected to the wrong network. Please connect to the
          correct network to continue.
        </p>
        <div className="w-4/5 mx-auto">
          <div className="mb-3 text-start">
            <p className="font-semibold text-text-label">Current Network:</p>
            <p className="text-accent font-semibold">
              {chains.find((chain) => chain.chainId === chainId)?.name ||
                "Unknown Network"}
            </p>
          </div>
          <div className="mb-3 text-start">
            <p className="font-semibold text-text-label">Target Network:</p>
            <p className="text-accent font-semibold">
              {
                chains.find(
                  (chain) => chain.chainId === Number(preferredNetwork)
                )?.name
              }
            </p>
          </div>
        </div>

        <Button onClick={() => open({ view: "Networks" })} type="button">
          {t("network.switch")}
        </Button>
      </div>
    </div>
  );
}
