import { ReactElement, ReactNode } from "react";
import { TailSpin } from "react-loader-spinner";

interface ButtonProps {
  type: "button" | "submit" | "reset";
  variant?:
    | "primary"
    | "secondary"
    | "simple"
    | "remove"
    | "accent-outline"
    | "accent";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: ReactNode | ReactElement | string;
  loading?: boolean;
  loadingText?: string;
  isFlex?: boolean;
}

const getStyleByVariant = (variant: string) => {
  if (variant === "primary") {
    return `buttonGradient disabled:opacity-50 rounded-full px-10 py-3 text-white font-semibold text-base transition-all ease-in-out duration-500`;
  }
  if (variant === "secondary") {
    return `bg-inputBackground disabled:opacity-50 rounded-full px-10 py-3 text-white font-semibold text-base transition-all ease-in-out duration-500`;
  }
  if (variant === "simple") {
    return `bg-transparent disabled:opacity-50 rounded-full px-10  text-accent font-semibold text-sm transition-all ease-in-out duration-500`;
  }
  if (variant === "remove") {
    return `bg-transparent hover:bg-red-600 hover:text-white disabled:opacity-50 rounded-full px-10 py-2  text-red-600 font-semibold text-sm transition-all ease-in-out duration-500`;
  }
};

export default function Button({
  type,
  onClick,
  disabled,
  className,
  children,
  loading,
  loadingText,
  isFlex,
  variant = "primary",
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={() => !disabled && onClick && onClick()}
      className={` ${
        isFlex ? "flex items-center gap-2" : ""
      } ${getStyleByVariant(variant)} ${className} ${
        disabled ? "pointer-events-none" : ""
      }`}
    >
      {loading ? (
        <span className="flex items-center gap-2 justify-center text-base">
          <TailSpin
            color="#fff"
            height={20}
            width={20}
            strokeWidth={3}
            wrapperClass="ml-2"
          />
          {loadingText ? <span>{loadingText}</span> : null}
        </span>
      ) : (
        children
      )}
    </button>
  );
}
