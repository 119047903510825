import { createTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const emotionCache = createCache({
  key: "css",
});

const mainTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: "transparent !important",
          borderRadius: "0 !important",
          border: "none",
          paddingRight: "0 !important",
          fontFamily: "Objectivity, sans-serif",
        },
        disabled: {
          color: "#fff !important",
          WebkitTextFillColor: "#fff !important",
        },

        input: {
          cursor: "pointer",
          paddingLeft: "0 !important",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
        },
        input: {
          ":disabled": {
            color: "#fff !important",
            WebkitTextFillColor: "#fff !important",
            paddingTop: "0.5rem !important",
          },
        },

        notchedOutline: {
          border: "none",
        },
      },
    },
  },
});

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={mainTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default ThemeWrapper;
