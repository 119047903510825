import { ethers } from "ethers";
import { apiCallHandler } from "../../api/axiosInstance";
import { verifySignature } from "../../api/authApi";
import { toast } from "react-toastify";

export const signWallet = async (
  walletProvider: any,
  walletAddress: string,
  nonce: string
) => {
  const provider = new ethers.providers.Web3Provider(walletProvider);
  const signer = provider.getSigner();
  const signature = await signer.signMessage(nonce);
  return signature;
};

export const signatureVerify = async (
  walletAddress: string,
  signature: string,
  onSuccess: (signature: string) => void,
  onError?: (error: any) => void
) => {
  apiCallHandler({
    apiCall: verifySignature,
    apiCallParams: [walletAddress, signature],
    onSuccess: (data: any) => {
      console.log(data);
      onSuccess(signature);
    },
    onError: (error: any) => {
      console.log(error);
      onError && onError(error);
    },
  });
};

interface Web3RequestHandlerProps {
  web3Call: any;
  params: any[];
  onSuccess: (data: any) => void;
  onError?: (error: any) => void;
  onChange?: (state: boolean) => void;
}

export const web3RequestHandler = async ({
  web3Call,
  params,
  onSuccess = () => {},
  onError = () => {},
  onChange = () => {},
}: Web3RequestHandlerProps) => {
  try {
    onChange(true);
    const response = await web3Call(...params);
    onSuccess(response);
  } catch (error) {
    onError(error);
  } finally {
    onChange(false);
  }
};
