import { Outlet } from "react-router";
import useAuthContext, { AuthCTXProvider } from "../contexts/authContext";
import { ReactNode, useEffect } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { AxiosError, AxiosResponse } from "axios";
import {
  createUserByPublicAddress,
  generateNonce,
  getAuthByPublicAddress,
  verifySignature,
} from "../api/authApi";
import { apiCallHandler } from "../api/axiosInstance";
import {
  signWallet,
  signatureVerify,
  web3RequestHandler,
} from "../shared/utils/web3";
import { setRef } from "@mui/material";

interface SignatureInfoInterface {
  address: string;
  signature: string;
}

function AuthWrapper({ children }: { children: ReactNode }) {
  const { isConnected, address } = useWeb3ModalAccount();
  const { setSignature, setAuth, setProcessingAuth, setRefferalID } =
    useAuthContext();
  const { walletProvider } = useWeb3ModalProvider();

  const runGenerateNonce = (walletAddress: string) => {
    apiCallHandler({
      apiCall: generateNonce,
      apiCallParams: [walletAddress],
      onSuccess: ({ data }: AxiosResponse) => {
        runSignWallet(walletAddress, data);
      },
      onError: (error: AxiosError) => {
        console.log("error generating nonce", error);
        setProcessingAuth(false);
      },
    });
  };

  const runSignWallet = (walletAddress: string, nonce: string) => {
    web3RequestHandler({
      web3Call: signWallet,
      params: [walletProvider, walletAddress, nonce],
      onSuccess: (signature: string) => {
        runVerifyWallet(walletAddress, signature);
      },
      onError: (error: any) => {
        console.log("error signing wallet", error);
        setProcessingAuth(false);
      },
    });
  };

  const runVerifyWallet = (
    walletAddress: string,
    signature: string,
    shouldGetNonce: boolean = false
  ) => {
    apiCallHandler({
      apiCall: verifySignature,
      apiCallParams: [walletAddress, signature],
      onSuccess: (_) => {
        handleSignatureSuccess(walletAddress, signature);
      },
      onError: (error: AxiosError) => {
        console.log(error);
        localStorage.removeItem("signatureInfo");
        if (shouldGetNonce) runGenerateNonce(walletAddress);
      },
    });
  };

  const getUserByAddress = (walletAddress: string) => {
    apiCallHandler({
      apiCall: getAuthByPublicAddress,
      apiCallParams: [walletAddress],
      onSuccess: (response: AxiosResponse) => {
        setAuth({
          id: response.data.id,
          publicAddress: response.data.publicAddress,
        });
        setRefferalID(response.data.referralAddress);
        handleSignatureCheck(walletAddress);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          let referralID = localStorage.getItem("refferalID") || null;
          runCreateUser(walletAddress, referralID);
        }
      },
    });
  };

  const runCreateUser = (walletAddress: string, refferalID: string | null) => {
    apiCallHandler({
      apiCall: createUserByPublicAddress,
      apiCallParams: [walletAddress, refferalID],
      onSuccess: (response: AxiosResponse) => {
        setAuth({
          id: response.data.id,
          publicAddress: response.data.publicAddress,
        });
        localStorage.removeItem("refferalID");
        setRefferalID(refferalID);
        runGenerateNonce(walletAddress);
      },
      onError: (error: AxiosError) => {
        console.log("error creating user", error);
        setProcessingAuth(false);
      },
    });
  };

  const handleSignatureCheck = (walletAddress: string) => {
    let signatureStorageRef = localStorage.getItem("signatureInfo");
    if (signatureStorageRef) {
      let signatureInfo: SignatureInfoInterface =
        JSON.parse(signatureStorageRef);
      if (signatureInfo.address === walletAddress) {
        runVerifyWallet(walletAddress, signatureInfo.signature, true);
      } else {
        runGenerateNonce(walletAddress);
      }
    } else {
      runGenerateNonce(walletAddress);
    }
  };

  const handleSignatureSuccess = (walletAddress: string, signature: string) => {
    localStorage.setItem(
      "signatureInfo",
      JSON.stringify({
        address: walletAddress,
        signature,
      })
    );
    setSignature(signature);
    setProcessingAuth(false);
  };

  const handleAuth = () => {
    setProcessingAuth(true);

    if (isConnected && address) {
      getUserByAddress(address);
    } else {
      setProcessingAuth(false);
      setAuth(null);
      setSignature(null);
    }
  };

  useEffect(() => {
    setProcessingAuth(true);
    setSignature("");
    handleAuth();
  }, [isConnected, address]);

  return <>{children}</>;
}

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <AuthCTXProvider>
      <AuthWrapper>
        {children}
        <Outlet />
      </AuthWrapper>
    </AuthCTXProvider>
  );
}
