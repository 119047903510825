import { useEffect, useState } from "react";
import Input from "../../../components/input/input";
import Button from "../../../components/control/button";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { web3RequestHandler } from "../../../shared/utils/web3";
import {
  TokenSaleCollateral,
  approveTokenSale,
  buyTokenSale,
  checkTokenSaleCollateralAllowance,
  getTokenInfos,
  getTokenSaleActive,
  getTokenSaleBalance,
  getTokenSalePrice,
  getTokenSaleSold,
  getTokenSymbol,
  tokenSaleContractConfig,
} from "../../../service/TokenService";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";

enum LoadingStates {
  WALLET,
  PURCHASE,
  APPROVE,
  ALLOWANCE,
}

const TokenCollateralOption = ({
  onCollateralChange,
  activeCollateral,
  collateral,
  block,
  balance,
  loadingBalance,
}: {
  onCollateralChange: () => void;
  balance: number;
  loadingBalance: boolean;
  activeCollateral: boolean;
  collateral: TokenSaleCollateral;
  block: boolean;
}) => {
  return (
    <div
      className={`flex flex-col gap-2 items-center px-6 py-4 rounded-lg border border-border justify-center cursor-pointer transition-all ease-in-out duration-200  ${
        activeCollateral ? "bg-accent text-black" : "bg-transparent text-white"
      } ${block ? "opacity-70" : ""}`}
      onClick={() => !block && onCollateralChange()}
    >
      <div className="flex gap-2 justify-center">
        <img
          src={collateral.image}
          alt={collateral.symbol}
          className="w-6 h-6"
        />
        <p>{collateral.symbol}</p>
      </div>
      <p className="flex items-center gap-1">
        Balance:{" "}
        {loadingBalance ? (
          <TailSpin
            color={activeCollateral ? "#000" : "#00DCFF"}
            height={16}
            width={16}
            strokeWidth={2}
          />
        ) : (
          parseFloat(String(balance.toFixed(8)))
        )}
      </p>
    </div>
  );
};

const TokenSalePurchase = ({
  saleActive,
  loadingSaleActive,
  collateral,
  price,
  balance,
  setRunningBuy,
  reload,
}: {
  saleActive: boolean;
  loadingSaleActive: boolean;
  collateral: TokenSaleCollateral;
  price: { amount: number; loading: boolean };
  balance: { amount: number; loading: boolean };
  setRunningBuy: (running: boolean) => void;
  reload: () => void;
}) => {
  const [amount, setAmount] = useState<string>("0");
  const [loading, setLoading] = useState<LoadingStates | null>(null);
  const { walletProvider } = useWeb3ModalProvider();

  const { isConnected, address } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { data: modalEvents } = useWeb3ModalEvents();

  const getAllowance = () => {
    setLoading(LoadingStates.ALLOWANCE);
    web3RequestHandler({
      web3Call: checkTokenSaleCollateralAllowance,
      params: [
        address,
        collateral.address,
        Number(amount),
        tokenSaleContractConfig.chainId,
      ],
      onSuccess: (data) => {
        if (data) {
          setLoading(LoadingStates.PURCHASE);
          runBuyToken();
        } else {
          getApproval();
        }
      },
      onError: (error) => {
        if (error && error.error) {
          toast.error(error.error.message);
        }
      },
    });
  };

  const getApproval = () => {
    setLoading(LoadingStates.APPROVE);
    web3RequestHandler({
      web3Call: approveTokenSale,
      params: [address, Number(amount), collateral.address, walletProvider],
      onSuccess: (data) => {
        setLoading(LoadingStates.PURCHASE);
        runBuyToken();
      },
      onError: (error) => {
        if (error && error.error) {
          toast.error(error.error.message);
        }
      },
    });
  };

  const runBuyToken = () => {

    web3RequestHandler({
      web3Call: buyTokenSale,
      params: [
        parseFloat(String((Number(amount) / Number(price.amount)).toFixed(4))),
        collateral.address,
        walletProvider,
      ],
      onSuccess: (data) => {
        toast.success("Purchase Successful");
        setLoading(null);
        reload();
      },
      onError: (error) => {
        if (error && error.error) {
          toast.error(error.error.message);
        }
        setLoading(null);
      },
    });
  };

  useEffect(() => {
    if (loading === LoadingStates.PURCHASE) {
      setRunningBuy(true);
    } else {
      setRunningBuy(false);
    }
  }, [loading]);

  useEffect(() => {
    if (modalEvents.event === "MODAL_OPEN" && !isConnected) {
      setLoading(LoadingStates.WALLET);
    } else {
      setLoading(null);
    }
  }, [modalEvents, isConnected]);

  return (
    <div className="flex flex-col gap-5 w-full ">
      <div className="grid sm:grid-cols-2 gap-3">
        <div className="flex flex-col gap-2">
          <div className="flex items-end justify-between pl-2 text-text-label">
            <p className="text-base font-semibold">Amount</p>

            <p
              className="text-accent font-semibold cursor-pointer ml-2 text-sm "
              onClick={() => setAmount(String(balance.amount))}
            >
              MAX
            </p>
          </div>
          <Input
            placeholder="0.00"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            suffix={
              <img
                src={collateral.image}
                alt={collateral.symbol}
                className="w-6 h-6"
              />
            }
            disabled={!saleActive || loading !== null || loadingSaleActive}
            type="number"
          />
        </div>
        <Input
          readOnly
          label="You'll receive"
          placeholder="0.00"
          value={parseFloat(
            String((Number(amount) / Number(price.amount)).toFixed(4))
          )}
          displayReadOnlyAsInput
          disabled
          suffix={<>BSCB</>}
        />
      </div>

      {isConnected ? (
        <Button
          type="button"
          variant="primary"
          loading={loading !== null || loadingSaleActive}
          loadingText={
            loadingSaleActive
              ? "Checking Sale Status"
              : loading === LoadingStates.ALLOWANCE
              ? "Checking Allowance"
              : loading === LoadingStates.APPROVE
              ? "Approving"
              : loading === LoadingStates.PURCHASE
              ? "Purchasing"
              : ""
          }
          onClick={() => {
            getAllowance();
          }}
          disabled={
            loading !== null ||
            Number(amount) === 0 ||
            !saleActive ||
            loadingSaleActive
          }
        >
          {saleActive ? "Purchase" : "Sale is not active"}
        </Button>
      ) : (
        <Button
          type="button"
          variant="primary"
          loading={loading !== null}
          loadingText={"Connecting Wallet"}
          onClick={() => {
            open();
          }}
          disabled={loading !== null}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  );
};

export default function TokenSale() {
  const [saleActive, setSaleActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isConnected, address } = useWeb3ModalAccount();
  const [runningBuy, setRunningBuy] = useState(false);
  const [collateral, setCollateral] = useState<TokenSaleCollateral>(
    tokenSaleContractConfig.collaterals[0] as TokenSaleCollateral
  );

  const [price, setPrice] = useState({
    amount: 1,
    loading: false,
  });
  const [totalSold, setTotalSold] = useState({
    amount: 0,
    loading: false,
  });
  const [buyBalance, setBuyBalance] = useState({
    amount: 0,
    loading: false,
  });
  const [balances, setBalances] = useState({
    usdt: {
      amount: 0,
      loading: false,
    },
    usdc: {
      amount: 0,
      loading: false,
    },
  });
  const getBoughtBalance = () => {
    web3RequestHandler({
      web3Call: getTokenSaleBalance,
      params: [address, tokenSaleContractConfig.chainId],
      onSuccess: (data) => {
        setBuyBalance((prev) => ({ ...prev, amount: data }));
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) => setBuyBalance((prev) => ({ ...prev, loading })),
    });
  };

  const getPrice = () => {
    web3RequestHandler({
      web3Call: getTokenSalePrice,
      params: [tokenSaleContractConfig.chainId],
      onSuccess: (data) => {
        setPrice((prev) => ({ ...prev, amount: data }));
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) => setPrice((prev) => ({ ...prev, loading })),
    });
  };

  const getSold = () => {
    web3RequestHandler({
      web3Call: getTokenSaleSold,
      params: [tokenSaleContractConfig.chainId],
      onSuccess: (data) => {
        setTotalSold((prev) => ({ ...prev, amount: data }));
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) => setTotalSold((prev) => ({ ...prev, loading })),
    });
  };

  useEffect(() => {
    // if (saleActive) {
    getPrice();
    getSold();
    // }
  }, [saleActive]);

  useEffect(() => {
    if (isConnected) {
      getBoughtBalance();
    }
  }, [isConnected]);

  const getIsTokenSaleActive = () => {
    web3RequestHandler({
      web3Call: getTokenSaleActive,
      params: [tokenSaleContractConfig.chainId],
      onSuccess: (data) => {
        setSaleActive(data);
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) => setLoading(loading),
    });
  };

  const getBalance = () => {
    web3RequestHandler({
      web3Call: getTokenInfos,
      params: [
        tokenSaleContractConfig.collaterals[0].address,
        address,
        tokenSaleContractConfig.chainId,
      ],
      onSuccess: (data) => {
        console.log(data);
        setBalances((prev) => ({
          ...prev,
          usdc: {
            ...prev.usdc,
            amount: data.balance,
          },
        }));
        // setBalance((prev) => ({ ...prev, amount: data.balance }));
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) =>
        setBalances((prev) => ({
          ...prev,
          usdc: {
            ...prev.usdc,
            loading,
          },
        })),
    });
    web3RequestHandler({
      web3Call: getTokenInfos,
      params: [
        tokenSaleContractConfig.collaterals[1].address,
        address,
        tokenSaleContractConfig.chainId,
      ],
      onSuccess: (data) => {
        console.log(data);
        setBalances((prev) => ({
          ...prev,
          usdt: {
            ...prev.usdt,
            amount: data.balance,
          },
        }));
      },
      onError: (error) => {
        console.log(error);
      },
      onChange: (loading) =>
        setBalances((prev) => ({
          ...prev,
          usdt: {
            ...prev.usdt,
            loading,
          },
        })),
    });
  };

  useEffect(() => {
    if (isConnected) {
      getBalance();
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected) {
      getIsTokenSaleActive();
    }
  }, [isConnected]);

  return (
    <div className="border justify-self-center grow  border-border p-px rounded-lg poolCardGradient w-full sm:w-[560px]  h-max ">
      <div className="rounded-lg  bg-contentBox px-6 pb-5 pt-8  ">
        <div className="relative z-[4] flex flex-col gap-4">
          <h2 className="text-center font-semibold text-2xl mb-2 glowWhite pb-3 ">
            BSCBank Token Sale!
          </h2>

          <p className="flex items-center justify-center text-text-label font-semibold">
            Your Purchased $BSCB =
            <span className="text-white ml-1">
              {buyBalance.loading ? (
                <TailSpin color="#00DCFF" height={20} width={20} />
              ) : (
                buyBalance.amount
              )}
            </span>
          </p>

          <div className="flex items-center gap-8">
            <span className="bg-borderSeparator w-full h-px block"></span>
            <p className="flex whitespace-nowrap w-full items-center justify-center text-text-label font-semibold">
              1 $BSCB ={" "}
              <span className="text-white flex items-center gap-1 ml-1">
                {price.loading ? (
                  <TailSpin color="#00DCFF" height={16} width={16} />
                ) : (
                  price.amount
                )}{" "}
                {collateral.symbol}
              </span>
            </p>
            <span className="bg-borderSeparator w-full h-px block"></span>
          </div>
          <div className="grid sm:grid-cols-2 gap-3">
            {tokenSaleContractConfig.collaterals.map(
              (collateralOption, index) => (
                <TokenCollateralOption
                  onCollateralChange={() => setCollateral(collateralOption)}
                  activeCollateral={
                    collateralOption.address === collateral.address
                  }
                  collateral={collateralOption}
                  block={runningBuy}
                  balance={
                    collateralOption.symbol === "USDC"
                      ? balances.usdc.amount
                      : balances.usdt.amount
                  }
                  loadingBalance={
                    collateralOption.symbol === "USDC"
                      ? balances.usdc.loading
                      : balances.usdt.loading
                  }
                  key={index}
                />
              )
            )}
          </div>
          <TokenSalePurchase
            saleActive={saleActive}
            loadingSaleActive={loading}
            collateral={collateral}
            setRunningBuy={setRunningBuy}
            reload={() => {
              getSold();
              getBoughtBalance();
              getBalance();
            }}
            balance={
              collateral.symbol === "USDC" ? balances.usdc : balances.usdt
            }
            price={price}
          />
        </div>
      </div>
    </div>
  );
}
