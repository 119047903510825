import SidemenuLink from "./sidemenuLink";

import { ReactComponent as AuroraIcon } from "../../../assets/icons/aurora.svg";
import { ReactComponent as LBPIcon } from "../../../assets/icons/lbp.svg";
import { ReactComponent as CurationIcon } from "../../../assets/icons/curation.svg";
import { ReactComponent as StakingIcon } from "../../../assets/icons/staking.svg";
import { ReactComponent as LeaderboardIcon } from "../../../assets/icons/leaderboard.svg";
import { ReactComponent as SupportIcon } from "../../../assets/icons/support.svg";
import { ReactComponent as DocsIcon } from "../../../assets/icons/docs.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import logo from "../../../assets/icons/logo_aurora.jpg";
import { Link } from "react-router-dom";

export default function SideMenu() {
  const [activeLink, setActiveLink] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const menuItems = [
    {
      text: t("menu.home"),
      icon: <HomeIcon />,
      link: "/",
      isActive: activeLink === "home",
      label: "Home",
    },
    {
      text: t("menu.lbps"),
      icon: <AuroraIcon />,
      link: "/lbps",
      isActive: activeLink === "LBPs",
      label: "LBPs",
    },
    {
      text: t("menu.create"),
      icon: <LBPIcon />,
      link: "/launchpad",
      isActive: activeLink === "Create",
      label: "Create",
    },
    {
      text: t("menu.curation"),
      icon: <CurationIcon />,
      link: "/curation",
      isActive: activeLink === "Curation",
      label: "Curation",
    },
    {
      text: t("menu.staking"),
      icon: <StakingIcon />,
      link: "/",
      isActive: activeLink === "Staking",
      label: "Staking",
    },
    {
      text: t("menu.leaderboard"),
      icon: <LeaderboardIcon />,
      link: "/leaderboard",
      isActive: activeLink === "Leaderboard",
      label: "Leaderboard",
    },
    {
      text: t("menu.support"),
      icon: <SupportIcon />,
      link: "/",
      isActive: activeLink === "Support",
      label: "Support",
    },
    {
      text: t("menu.docs"),
      icon: <DocsIcon />,
      link: "/",
      isActive: activeLink === "Docs",
      label: "Docs",
    },
    {
      text: t("menu.admin"),
      icon: <AuroraIcon />,
      link: "/admin/platform",
      isActive: activeLink === "Admin",
      label: "Admin",
      hidden: true,
    },
  ];

  useEffect(() => {
    setIsOpen(false);
    if (pathname === "/") setActiveLink("home");
    if (pathname === "/lbps" || pathname.includes("pools"))
      setActiveLink("LBPs");
    if (pathname.includes("/launchpad")) setActiveLink("Create");
    if (pathname.includes("/curation")) setActiveLink("Curation");
    if (pathname.includes("/staking")) setActiveLink("Staking");
    if (pathname.includes("/leaderboard")) setActiveLink("Leaderboard");
    if (pathname.includes("/support")) setActiveLink("Support");
    if (pathname.includes("/docs")) setActiveLink("Docs");
    if (pathname.includes("/admin")) setActiveLink("Admin");
  }, [pathname]);

  if (pathname === "/" || pathname === "/token-sale") return null;

  return (
    <>
      <div className="lg:fixed left-0 top-[0px] z-50   w-full xl:max-w-[260px] ">
        <div className="py-4 px-7 sm:h-[72px] border-b-2 border-b-borderSeparator flex items-center justify-between sm:flex-wrap sm:gap-4 gap-5 sm:flex-row flex-col ">
          <Link to={"/"} className="block">
            <img src={logo} alt="logo" className="max-h-[45px] h-full" />
          </Link>
          <div className="lg:hidden">
            <w3m-button label={t("wallet.connect")} />
          </div>
        </div>
        <aside className=" border-r-2 border-borderSeparator  block w-full   xl:justify-center justify-start  xl:px-3 px-0 mx-0 xl:pb-2 pb-4">
          <ul
            className="xl:hidden flex items-center justify-between py-4 cursor-pointer px-8 rounded-3xl bg-contentBox border-border border-b"
            onClick={() => setIsOpen(!isOpen)}
          >
            <li className="flex items-center justify-between w-full cursor-pointer mb-0 activeIcon">
              <span className="flex items-center gap-2">
                {menuItems.find((item) => item.isActive)?.icon}
                <p>{menuItems.find((item) => item.isActive)?.text}</p>
              </span>
              <ChevronIcon
                className={`w-4 h-4 cursor-pointer transition-all ease-in-out duration-200 ${
                  isOpen ? "" : "rotate-180"
                }`}
              />
            </li>
          </ul>
          <ul
            className={`lg:w-auto m-0 w-full lg:position-[unset] lg:mt-0 mt-4  lg:px-0 px-4 lg:bg-transparent bg-contentBox2 xl:z-[1] z-[10000] ${
              isOpen
                ? "max-h-fit xl:top-0 left top-full"
                : "max-h-0 xl:max-h-full xl:top-0 left top-full"
            } transition-all ease-in-out duration-ľ00 xl:overflow-visible overflow-hidden`}
          >
            {menuItems.map(
              (item, index) =>
                !item.hidden && (
                  <SidemenuLink
                    key={index}
                    text={item.text}
                    icon={item.icon}
                    link={item.link}
                    isActive={item.isActive}
                  />
                )
            )}
          </ul>
        </aside>
      </div>
      <div className="w-full max-w-[100vw] block xl:max-w-[260px]"></div>
    </>
  );
}
