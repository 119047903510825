import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import SideMenu from "./components/navigation/sidemenu/sidemenu";
import MainLayout from "./Layout";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import AuthLayout from "./layout/authLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TokenSale from "./pages/tokenSale";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "61d55e33c6309fd83cd72050832924ce";

// 2. Set chains
type Chain = {
  chainId: number;
  name: string;
  currency: string;
  explorerUrl: string;
  rpcUrl: string;
  logo: string;
};

export const chains: Chain[] = [
  {
    chainId: 11155111,
    name: "Sepolia",
    currency: "ETH",
    explorerUrl: "https://sepolia.etherscan.io/",
    rpcUrl: "https://sepolia.infura.io/v3/9c7e70b4bf234955945ff87b8149926e",
    logo: "https://www.datocms-assets.com/105223/1699489431-base-logo.png",
  },
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io/address/",
    rpcUrl:
      "https://ethereum.blockpi.network/v1/rpc/e4ffe6abe2183d932aee77e81cffcf2d0ccd0357 ",
    logo: "https://token-repository.dappradar.com/tokens?protocol=ethereum&contract=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&file=logo.png",
  },
];

export const multiChainConfiguration = [
  {
    chainId: 97,
    factoryAddress: process.env.REACT_APP_FACTORY_ADDRESS_97,
    sablierAddress: process.env.REACT_APP_SABLIER_ADDRESS_97,
    collaterals: [
      {
        currency: "WBNB",
        tokenAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
        logo: "https://coinando.com/static/assets/coins/wbnb-logo.png?v=3o",
      },
    ],
  },
  {
    chainId: 84532,
    factoryAddress: process.env.REACT_APP_FACTORY_ADDRESS_BASE_SEPOLIA,
    sablierAddress: process.env.REACT_APP_SABLIER_ADDRESS_BASE_SEPOLIA,
    collaterals: [
      {
        currency: "WETH",
        tokenAddress: "0x4200000000000000000000000000000000000006",
        logo: "https://token-repository.dappradar.com/tokens?protocol=ethereum&contract=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&file=logo.png",
      },
    ],
  },
  {
    chainId: 11155111,
    factoryAddress: process.env.REACT_APP_FACTORY_ADDRESS_SEPOLIA,
    sablierAddress: process.env.REACT_APP_SABLIER_ADDRESS_SEPOLIA,
    collaterals: [
      {
        currency: "WETH",
        tokenAddress: "0xD943A87F15525128fB437E0eAf5AbEC0E85586B5",
        logo: "https://token-repository.dappradar.com/tokens?protocol=ethereum&contract=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&file=logo.png",
      },
    ],
  },
  {
    chainId: 200810,
    factoryAddress: process.env.REACT_APP_FACTORY_ADDRESS_BITLAYER_TESTNET,
    sablierAddress: process.env.REACT_APP_SABLIER_ADDRESS_BITLAYER_TESTNET,
    collaterals: [
      {
        currency: "WBTC",
        tokenAddress: "0x3e57d6946f893314324c975aa9cebbdf3232967e",
        logo: "https://png.pngtree.com/png-vector/20220529/ourmid/pngtree-a-basic-bitcoin-logo-vector-graphic-with-clear-backdrop-vector-png-image_46626566.jpg",
      },
    ],
  },
  {
    chainId: 200901,
    factoryAddress: process.env.REACT_APP_FACTORY_ADDRESS_BITLAYER,
    sablierAddress: process.env.REACT_APP_SABLIER_ADDRESS_BITLAYER,
    collaterals: [
      {
        currency: "WBTC",
        tokenAddress: "0xff204e2681a6fa0e2c3fade68a1b28fb90e4fc5f",
        logo: "https://png.pngtree.com/png-vector/20220529/ourmid/pngtree-a-basic-bitcoin-logo-vector-graphic-with-clear-backdrop-vector-png-image_46626566.jpg",
      },
    ],
  },
];

export const availableChains = chains.map((chain) => chain.chainId);

const ethersConfig = defaultConfig({
  metadata: {
    name: "Web3Modal",
    description: "Web3Modal Laboratory",
    url: "https://web3modal.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  },
  defaultChainId: 11155111,
  rpcUrl: "https://cloudflare-eth.com",
});

createWeb3Modal({
  ethersConfig,
  chains,
  projectId,
  enableAnalytics: true,
  themeMode: "light",
  themeVariables: {
    "--w3m-color-mix": "#00DCFF",
    "--w3m-color-mix-strength": 20,
  },
});

function App() {
  return (
    <>
      <ToastContainer
        newestOnTop={true}
        pauseOnFocusLoss={false}
        limit={5}
        style={{
          zIndex: 100000,
        }}
      />
      <Router>
        <AuthLayout>
          <div className="font-objectivity lg:pt-[72px]">
            <Header />
            <div
              className={`flex xl:flex-row flex-col xl:pt-0 ${
                window.location.pathname !== "/" &&
                window.location.pathname !== "/token-sale"
                  ? "lg:pt-20"
                  : ""
              } pt-0`}
            >
              <SideMenu />
              <MainLayout>
                <Routes>
                  <Route path="/" element={<TokenSale />} />
                  {/* <Route path="/token-sale" element={<TokenSale />} /> */}

                </Routes>
              </MainLayout>
            </div>
          </div>
        </AuthLayout>
      </Router>
    </>
  );
}

export default App;
