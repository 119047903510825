import NetworkWarning from "../../components/warning/networkWarning";
import TokenSale from "./sections/tokenSale";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Button from "../../components/control/button";
import tokenSaleBG from "../../assets/img/tokenSale.jpg";
import { tokenSaleContractConfig } from "../../service/TokenService";

export default function TokenSalePage() {
  return (
    <div className=" pb-24 flex flex-col h-full relative tokenSalePage ">
      <NetworkWarning preferredNetwork={tokenSaleContractConfig.chainId} />
      {/* <div className="absolute top-0 left-0 w-full h-full z-[1]  overflow-hidden">
        <div className="w-1/2 h-auto aspect-square rounded-full bg-[#182135] opacity-50 blur-[164px] absolute z-[0] -left-1/4 -bottom-1/4"></div>

        <div className="w-1/2 scale-90 h-auto aspect-square rounded-full bg-[#182135] blur-[164px] absolute z-[0] -right-1/4 -top-1/4"></div>
      </div> */}
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          src={tokenSaleBG}
          alt="tokenSaleBG"
          className="w-full h-full object-cover"
        />
        <div className="bg-contentBox opacity-40 w-full h-full absolute top-0 left-0" />
      </div>
      <div className="relative z-[4] flex flex-col gap-24">
        <div className="sm:grid flex flex-col lg:grid-cols-2 gap-12 items-center sm:items-end justify-center w-4/5 mx-auto  mt-16">
          <div className="flex items-center flex-col gap-3 ">
            <div className="flex items-center gap-2  mb-3">
              <Logo className="w-[56px] h-[56px] " />
            </div>

            <h3 className="font-semibold text-3xl mb-3">BSCBANK Token Sale</h3>
            <div className="flex items-center gap-3">
              <a 
                href="/whitepaper.pdf" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none' }}
              >
                <Button type="button" variant="primary">
                  Whitepaper
                </Button>
              </a>
              {/* <div className=" p-0.5 rounded-full cursor-pointer w-full bg-clip-border border-2 text-accent border-accent hover:bg-accent hover:!text-black transition-all ease-in-out ">
                <div className="rounded-full text-center px-12 py-2 font-objectivity bg-transparent  ">
                  <p>Audit</p>
                </div>
              </div> */}
            </div>
          </div>

          <TokenSale />
        </div>
      </div>
    </div>
  );
}
