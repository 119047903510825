import StatusBar from "./StatusBar";

const Header = () => {
  return (
    <header className="border-b-2 border-borderSeparator relative z-[50]">
      {/* <TopBar /> */}
      {/* <Banner /> */}
      <StatusBar />
    </header>
  );
};

export default Header;
