import { axiosInstance } from "./axiosInstance";

export async function getAuthByPublicAddress(publicAddress: string) {
  return axiosInstance.get(`auth/${publicAddress}`);
}

export async function createUserByPublicAddress(
  publicAddress: string,
  refferalAddress?: string
) {
  return axiosInstance.post(`auth`, publicAddress, {
    params: {
      referalAddress: refferalAddress,
    },
  });
}

export async function generateNonce(publicAddress: string) {
  return axiosInstance.put(`auth/${publicAddress}/generate-nonce`);
}

export async function verifySignature(
  publicAddress: string,
  signature: string
) {
  return axiosInstance.post(
    `auth/${publicAddress}/verify-signature`,
    signature
  );
}
