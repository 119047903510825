import { InputProps } from "../../shared/interfaces";

interface CustomInputProps extends InputProps {
  value: string | any;
  textarea?: boolean;
  maxH?: number;
  minH?: number;
  className?: string;
  rootClassName?: string;
  displayReadOnlyAsInput?: boolean;
  forceTextStart?: boolean;
  wrapPrefix?: boolean;
  ignoreJustify?: boolean;
  showErrors?: boolean;
  variant?: "primary" | "secondary";
}

export default function Input({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  min,
  max,
  touched,
  required,
  name,
  id,
  tooltip,
  suffix,
  prefix,
  textarea,
  maxH,
  minH,
  className,
  rootClassName,
  disabled,
  readOnly,
  displayReadOnlyAsInput,
  forceTextStart,
  wrapPrefix,
  ignoreJustify,
  showErrors,
  variant = "primary",
}: CustomInputProps) {
  return (
    <>
      <div className={`flex flex-col gap-2 ${rootClassName}`}>
        {label && (
          <div className="pl-2 ">
            <label
              className="text-text-label text-base font-semibold"
              htmlFor={name}
            >
              {label}
            </label>
            {required && <span className="text-text-label ml-2">*</span>}
            {tooltip && <span className="text-blue-500"> {tooltip}</span>}
          </div>
        )}
        <div
          className={`flex gap-3 ${wrapPrefix && "flex-wrap"}  items-center ${
            !ignoreJustify && "justify-between"
          }    px-6 rounded-3xl border-2 transition-all ease-in-out duration-500  ${
            className && className
          } ${disabled && "opacity-50"} ${
            readOnly && !displayReadOnlyAsInput
              ? "bg-transparent border-none py-1"
              : `${
                  variant === "primary"
                    ? "bg-inputBackground2"
                    : "bg-inputBackground"
                }  p-3 ${
                  touched && error ? "border-red-600" : "border-borderInput"
                }`
          }`}
        >
          {prefix}

          {textarea ? (
            <textarea
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              required={required}
              name={name}
              id={id}
              className={`w-full   outline-none text-white placeholder:text-textOpaque ${
                prefix && !forceTextStart && "text-end"
              } ${readOnly ? "cursor-auto" : "cursor-text"} ${
                readOnly && !displayReadOnlyAsInput
                  ? "bg-transparent"
                  : `${
                      variant === "primary"
                        ? "bg-inputBackground2"
                        : "bg-inputBackground"
                    }`
              }`}
              style={{ maxHeight: maxH, minHeight: minH }}
              disabled={disabled}
              readOnly={readOnly}
            />
          ) : (
            <input
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              min={min}
              max={max}
              required={required}
              name={name}
              id={id}
              className={`w-full  outline-none text-white placeholder:text-textOpaque ${
                prefix && !forceTextStart && "text-end"
              }
              ${readOnly ? "cursor-auto" : "cursor-text"} 
              ${
                readOnly && !displayReadOnlyAsInput
                  ? "bg-transparent"
                  : `${
                      variant === "primary"
                        ? "bg-inputBackground2"
                        : "bg-inputBackground"
                    }`
              }`}
              disabled={disabled}
              readOnly={readOnly}
            />
          )}
          {suffix}
        </div>
        {showErrors && touched && error && (
          <p className="text-error font-semibold text-xs mt-1">
            <span className="bg-error w-1.5 h-1.5 rounded-full  mr-2 inline-block"></span>
            {error}
          </p>
        )}
        {/* {touched && error && <p>{error}</p>} */}
      </div>
    </>
  );
}
