import { useTranslation } from "react-i18next";
import logo from "../assets/icons/logo2.png";
import { Link, useLocation } from "react-router-dom";
const StatusBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <div
      className={`status-bar ${
        pathname !== "/" && pathname !== "/token-sale"
          ? "lg:flex hidden"
          : "flex"
      }  lg:fixed top-0 left-0 w-full z-[50] border-b-2 border-borderSeparator`}
    >
      {pathname === "/" || pathname === "/token-sale" ? (
        <Link to={"/"} className="block">
          <img src={logo} alt="logo" className="max-h-[45px] h-full" />
        </Link>
      ) : (
        <div></div>
      )}
      <w3m-button label={t("wallet.connect")} />
    </div>
  );
};

export default StatusBar;
