import { ReactElement } from "react";
import { Link } from "react-router-dom";

interface SidemenuLinkProps {
  text: string;
  icon: ReactElement;
  link: string;
  isActive?: boolean;
}

export default function SidemenuLink({
  text,
  icon,
  link,
  isActive,
}: SidemenuLinkProps) {
  return (
    <li>
      <div
        className={`p-px ${
          isActive && "buttonGradient max-w-max  ml-2 lg:scale-105"
        }  rounded-full origin-left`}
      >
        <Link
          to={link}
          className={`flex items-center max-w-max gap-2 ${
            isActive && "active"
          } `}
        >
          {icon}
          <span className="text-sm">{text}</span>
        </Link>
      </div>
    </li>
  );
}
