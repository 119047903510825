import { Auth } from "../shared/interfaces";
import { createContext, useContext, useState } from "react";

interface AuthContextValues {
  auth: Auth | null;
  signature: string | null;
  processingAuth: boolean;
  refferalID: string | null;
  setRefferalID: (refferalID: string | null) => void;
  setProcessingAuth: (state: boolean) => void;
  setSignature: (signature: string | null) => void;
  setAuth: (auth: Auth | null) => void;
}

export const AuthCTX = createContext<AuthContextValues>({
  auth: null,
  signature: null,
  processingAuth: false,
  refferalID: null,
  setRefferalID: () => {},
  setProcessingAuth: () => {},
  setSignature: () => {},
  setAuth: () => {},
});

const useAuthContext = () => {
  const authCtx = useContext(AuthCTX);
  if (!authCtx) {
    throw new Error("useAuthContext must be used within AuthCTXProvider");
  }
  return authCtx;
};

export const AuthCTXProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [processingAuth, setProcessingAuth] = useState(false);
  const [refferalID, setRefferalID] = useState<string | null>(null);

  const value = {
    auth,
    signature,
    processingAuth,
    refferalID,
    setRefferalID,
    setProcessingAuth,
    setAuth,
    setSignature,
  };

  return <AuthCTX.Provider value={value}>{children}</AuthCTX.Provider>;
};

export default useAuthContext;
