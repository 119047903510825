import axios, { AxiosInstance, AxiosResponse } from "axios";

interface ApiCallHandlerProps {
  apiCall: any;
  apiCallParams?: any[];
  onSuccess: (data: AxiosResponse) => void;
  onChange?: (state: boolean) => void;
  onError?: (error: any) => void;
}

const API_URL = process.env.REACT_APP_API_URL;

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

export const apiCallHandler = async ({
  apiCall,
  apiCallParams,
  onSuccess = () => {},
  onChange = () => {},
  onError = () => {},
}: ApiCallHandlerProps) => {
  try {
    onChange(true);
    const apiCallResponse: AxiosResponse = await apiCall(
      ...(apiCallParams || [])
    );
    onSuccess(apiCallResponse);
  } catch (error) {
    onError(error);
  } finally {
    onChange(false);
  }
};
