import { BigNumber, ethers } from "ethers";
import abiErc20 from "./data/erc20Abi.json";
import abiLbpBoostrapPool from "./data/lbpBootstrapPoolAbi.json";
import abiTokenSale from "./data/tokenSaleAbi.json";
import { chains, multiChainConfiguration } from "../App";
import { getExchangeRate } from "../shared/constant";

export interface TokenSaleCollateral {
  address: string;
  symbol: string;
  image: string;
}

const tokenSaleContractConfig = {
  // chainId: 1,
  // smartContract: "0xF058618360190C434538d689446e04F1f836C602",
  // tokenAddress: "0x318c9C525Dd4F77f4C009eeeDBCC1301d1f214c8",
  // collaterals: [
  //   {
  //     address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  //     symbol: "USDC",
  //     image:
  //       "https://cdn.iconscout.com/icon/premium/png-256-thumb/usd-coin-usdc-7152448-5795279.png",
  //   },
  //   {
  //     address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  //     symbol: "USDT",
  //     image:
  //       "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Tether-USDT-icon.png",
  //   },
  // ],

  chainId: 11155111,
  smartContract: "0xf6cd3c7d61bC51420f5f6B38C4EDec5908e53194",
  tokenAddress: "0x3F572a5671A0f4cc74169BC3439515DF54C15509",
  collaterals: [
    {
      address: "0x5260c03CbD1d79E3B3E14b359947b3489D9E0fef",
      symbol: "USDC",
      image:
        "https://cdn.iconscout.com/icon/premium/png-256-thumb/usd-coin-usdc-7152448-5795279.png",
    },
    {
      address: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
      symbol: "USDT",
      image:
        "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Tether-USDT-icon.png",
    },
  ],
};

const getTokenInfos = async (
  tokenAddress: string,
  connectedUser: string,
  networkId: any
): Promise<{ balance: number; symbol: string }> => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    jsonProvider
  );
  const balance: BigNumber = connectedUser
    ? await tokenContract.balanceOf(connectedUser)
    : 0;
  const symbol: string = await tokenContract.symbol();
  const decimal = await tokenContract.decimals();
  return {
    balance: Number(balance) / 10 ** decimal,
    symbol: symbol,
  };
};

const getPoolRefundState = async (poolAddress: string, networkId: any) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const poolContract = new ethers.Contract(
    poolAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const refundState = await poolContract.refund();
  return refundState;
};

const getTokenSymbol = async (tokenAddress: string, networkId: any) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    jsonProvider
  );
  return await tokenContract.symbol();
};

const getTokenName = async (tokenAddress: string, networkId: any) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    jsonProvider
  );
  return await tokenContract.name();
};

const approveTokenToAddress = async (
  tokenAddress: string,
  toAddress: string,
  amount: BigNumber,
  signer: any
) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    signer
  );
  const tsx = await tokenContract.approve(toAddress, amount);
  await tsx.wait();
};

const convertNumberToBigNumberWithDecimals = (
  tokenBalance: number,
  decimals: number
): BigNumber => {
  return ethers.utils.parseUnits(tokenBalance.toFixed(decimals), decimals);
};

const getChainConfig = (networkId: any) => {
  return multiChainConfiguration.find(
    (token) => token.chainId === Number(networkId)
  );
};


const calculatePrice = async (
  inputs: {
    collateralAddress: string;
    tokenAddress: string;
    collateralAmount: number;
    tokenAmount: number;
  },
  networkId: number
) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const collateralDecimals = await decimalsOf(
    inputs.collateralAddress,
    jsonProvider
  );
  const tokenDecimals = await decimalsOf(inputs.tokenAddress, jsonProvider);
  return (
    ((inputs.collateralAmount * 10 ** collateralDecimals) /
      (inputs.tokenAmount * 10 ** tokenDecimals)) *
    getExchangeRate(inputs.collateralAddress)
  );
};

const decimalsOf = async (tokenAddress: string, provider: any) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    provider
  );
  return await tokenContract.decimals();
};

const getPreviewSharesOut = async (
  toTokenAddress: string,
  amount: number,
  lbpContractAddress: string,
  networkId: any
) => {

  const jsonProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const decimalsValue = await decimalsOf(toTokenAddress, jsonProvider);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amount,
    decimalsValue
  );

  const previewSharesOut = await lpbContract.previewSharesOut(amountValue);

  return Number(previewSharesOut) / 10 ** decimalsValue;
};

const getPreviewAssetsOut = async (
  toTokenAddress: string,
  amount: number,
  lbpContractAddress: string,
  networkId: any
) => {

  const jsonProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const decimalsValue = await decimalsOf(toTokenAddress, jsonProvider);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amount,
    decimalsValue
  );

  const previewAssetsOut = await lpbContract.previewAssetsOut(amountValue);

  return Number(previewAssetsOut) / 10 ** decimalsValue;
};

const getPreviewAssetsIn = async (
  toTokenAddress: string,
  amountOut: number,
  lbpContractAddress: string,
  networkId: any
) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const decimalsValue = await decimalsOf(toTokenAddress, jsonProvider);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amountOut,
    decimalsValue
  );

  const previewSharesIn = await lpbContract.previewAssetsIn(amountValue);

  return Number(previewSharesIn) / 10 ** decimalsValue;
};

const getJsonRpcProvider = (networkId: any) => {
  const networkChain = chains.find(
    (chain) => chain?.chainId === parseInt(networkId)
  );
  const jsonProvider = new ethers.providers.JsonRpcProvider(
    networkChain?.rpcUrl
  );
  return jsonProvider;
};

const getPreviewSharesIn = async (
  toTokenAddress: string,
  amountOut: number,
  lbpContractAddress: string,
  networkId: any
) => {

  const jsonProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const decimalsValue = await decimalsOf(toTokenAddress, jsonProvider);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amountOut,
    decimalsValue
  );

  const previewSharesIn = await lpbContract.previewSharesIn(amountValue);

  return Number(previewSharesIn) / 10 ** decimalsValue;
};

const approve = async (
  fromAddress: string,
  lbpContractAddress: string,
  amount: number,
  walletProvider: any
) => {

  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();

  const decimalsValue = await decimalsOf(fromAddress, signer);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amount,
    decimalsValue
  );

  await approveTokenToAddress(
    fromAddress,
    lbpContractAddress,
    amountValue,
    signer as any
  );
};

const getPurchasedShares = async (
  connectedAddress: string,
  lbpContractAddress: string,
  lbpTokenAddress: string,
  networkId: any
) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonProvider
  );
  const decimalsValue = await decimalsOf(lbpTokenAddress, jsonProvider);
  const purchasedShares = await lpbContract.purchasedShares(connectedAddress);
  const sharesAmount = Number(purchasedShares) / 10 ** decimalsValue;

  return sharesAmount;
};

const isAllowedAmount = async (
  tokenAddress: string,
  lbpContractAddress: string,
  amount: number,
  connectedUser: string,
  networkId: any
) => {

  const jsonProvider = getJsonRpcProvider(networkId);

  const decimalsValue = await decimalsOf(tokenAddress, jsonProvider);
  const amountValue = convertNumberToBigNumberWithDecimals(
    amount,
    decimalsValue
  );

  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    jsonProvider
  );
  const allowedAmount: BigNumber = await tokenContract.allowance(
    connectedUser,
    lbpContractAddress
  );

  return allowedAmount > amountValue;
};

const executeSwappingMethod = async (
  fromAddress: string,
  toAddress: string,
  lbpContractAddress: string,
  assetsIn: number,
  estimatedAssetsOut: number,
  slippage: number,
  connectedUserAddress: string,
  isBuy: boolean,
  walletProvider: any,
  networkId: any,
  getProofCallback: any,
  referral: string | undefined
) => {
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();

  const decimalFrom = await decimalsOf(fromAddress, signer);
  const decimalTo = await decimalsOf(toAddress, signer);

  const minSharesOut =
    estimatedAssetsOut - (estimatedAssetsOut * slippage) / 100;

  const formattedMinSharesOut = convertNumberToBigNumberWithDecimals(
    minSharesOut,
    decimalTo
  );
  const formattedAssertIn = convertNumberToBigNumberWithDecimals(
    assetsIn,
    decimalFrom
  );

  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    signer
  );

  let whiteListed = await isWhiteListed(lbpContractAddress, networkId);
  let tsx;

  if (!whiteListed) {
    tsx = executeSwappingWithoutWhiteListing(
      formattedAssertIn,
      formattedMinSharesOut,
      connectedUserAddress,
      isBuy,
      lpbContract,
      referral
    );
  } else {

    let { data: proof } = await getProofCallback(lbpContractAddress);
    tsx = executeSwappingWithWhiteListing(
      formattedAssertIn,
      formattedMinSharesOut,
      connectedUserAddress,
      isBuy,
      lpbContract,
      proof,
      referral
    );
  }

  return tsx;
};

const executeSwappingWithoutWhiteListing = async (
  formattedAssertIn: BigNumber,
  formattedMinSharesOut: BigNumber,
  connectedUserAddress: string,
  isBuy: boolean,
  lpbContract: any,
  referral: string | undefined
) => {
  let methodName;
  let tsx;

  if (!referral) {
    if (isBuy) {
      methodName = "swapExactAssetsForShares(uint256,uint256,address)";
    } else {
      methodName = "swapExactSharesForAssets(uint256,uint256,address)";
    }
   
    tsx = await lpbContract[methodName](
      formattedAssertIn,
      formattedMinSharesOut,
      connectedUserAddress
    );
  } else {
    if (isBuy) {
      methodName = "swapExactAssetsForShares(uint256,uint256,address,address)";
    } else {
      methodName = "swapExactSharesForAssets(uint256,uint256,address,address)";
    }
    
    tsx = await lpbContract[methodName](
      formattedAssertIn,
      formattedMinSharesOut,
      connectedUserAddress,
      referral
    );
  }

  return await tsx.wait();
};

const executeSwappingWithWhiteListing = async (
  formattedAssertIn: BigNumber,
  formattedMinSharesOut: BigNumber,
  connectedUserAddress: string,
  isBuy: boolean,
  lpbContract: any,
  proof: any,
  referer: string | undefined
) => {
  let methodName;

  if (isBuy) {
    methodName =
      "swapExactAssetsForShares(uint256,uint256,address,address,bytes32[])";
  } else {
    methodName =
      "swapExactSharesForAssets(uint256,uint256,address,address,bytes32[])";
  }

  let tsx = await lpbContract[methodName](
    formattedAssertIn,
    formattedMinSharesOut,
    connectedUserAddress,
    referer ? referer : "0x0000000000000000000000000000000000000000",
    proof
  );

  return await tsx.wait();
};

// enable/disable whiteListing
const toggleWhitelisting = async (
  lbpContractAddress: string,
  walletProvider: any
) => {
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    signer
  );

  const tsx = await lpbContract.toggleWhitelisting();
  await tsx.wait();
  return tsx;
};

// update merkle root
const updateMerkleRoot = async (
  lbpContractAddress: string,
  rootHash: string,
  walletProvider: any
) => {
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    signer
  );
  const tsx = await lpbContract.updateMerkleRoot(rootHash);
  await tsx.wait();
  return tsx;
};

// to verify is whitelisting enabled
const isWhiteListed = async (lbpContractAddress: string, networkId: string) => {

  const jsonRpcProvider = getJsonRpcProvider(networkId);

  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonRpcProvider
  );

  const tsx = await lpbContract.whitelistingEnabled();
  return tsx;
};

const isSupportingSelling = async (
  lbpContractAddress: string,
  networkId: string
) => {
  const jsonRpcProvider = getJsonRpcProvider(networkId);

  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonRpcProvider
  );
 
  const tsx = await lpbContract.sellingAllowed();
  return tsx;
};

const getLbpGraphInput = async (
  lbpContractAddress: string,
  networkId: string
) => {
  
  const jsonRpcProvider = getJsonRpcProvider(networkId);

  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonRpcProvider
  );
 
  const tsx = await lpbContract.reservesAndWeights();
 
  return {
    ar: Number(tsx[0]),
    sr: Number(tsx[1]),
    collateralExchangeCurrentTime: 3000,
  };
};

const getTokenDecimals = async (tokenAddress: string, networkId: any) => {
  const jsonProvider = getJsonRpcProvider(networkId);
  const tokenContract = new ethers.Contract(
    tokenAddress,
    abiErc20 as any,
    jsonProvider
  );
  return await tokenContract.decimals();
};


const getSigner = (walletProvider: any) => {
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  return ethersProvider.getSigner();
};

const getTotalTokensToSell = async (
  lbpContractAddress: string,
  tokenAddress: string,
  networkId: any
) => {
  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const lpbContract = new ethers.Contract(
    lbpContractAddress,
    abiLbpBoostrapPool as any,
    jsonRPCProvider
  );
  const tokenDecimals = await getTokenDecimals(tokenAddress, networkId);

  const tokensToSell = await lpbContract.maxTotalSharesOut();

  let decimalDivisor = ethers.FixedNumber.from(10);
  for (let i = 0; i < tokenDecimals - 1; i++) {
    decimalDivisor = decimalDivisor.mulUnsafe(ethers.FixedNumber.from(10));
  }

  const tokensToSellValue = ethers.FixedNumber.from(tokensToSell);
  const tokensToSellEthers = tokensToSellValue.divUnsafe(decimalDivisor);

  return Number(tokensToSellEthers.toString());
};

const buyTokenSale = async (
  amount: number,
  collateralAddress: string,
  walletProvider: any
) => {

  let isUSDC =
    collateralAddress === tokenSaleContractConfig.collaterals[0].address;
  const tokenID = isUSDC ? 1 : 2;

  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();
  const tokenSaleContract = new ethers.Contract(
    tokenSaleContractConfig.smartContract as string,
    abiTokenSale as any,
    signer
  );

  let tokenAddress = tokenSaleContractConfig.tokenAddress;

  const decimals = await getTokenDecimals(  
    tokenAddress,
    tokenSaleContractConfig.chainId
  );
  const amountValue = convertNumberToBigNumberWithDecimals(
    Number(amount),
    decimals
  );

  const tsx = await tokenSaleContract.buyTokens(tokenID, amountValue);
  await tsx.wait();
};

const getTokenSalePrice = async (networkId: number) => {
  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const tokenSaleContract = new ethers.Contract(
    tokenSaleContractConfig.smartContract,
    abiTokenSale as any,
    jsonRPCProvider
  );

  let price: BigNumber = await tokenSaleContract.tokenPrice();

  const tokenDecimals = await getTokenDecimals(
    tokenSaleContractConfig.tokenAddress as string,
    networkId
  );

  let priceEthers: ethers.FixedNumber = ethers.FixedNumber.from(price);

  let decimalDivisor = ethers.FixedNumber.from(10);

  for (let i = 0; i < tokenDecimals - 1; i++) {
    decimalDivisor = decimalDivisor.mulUnsafe(ethers.FixedNumber.from(10));
  }

  priceEthers = priceEthers.divUnsafe(decimalDivisor);
  
  return priceEthers.toString();
};

const getTokenSaleSold = async (networkId: number) => {
  
  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const tokenSaleContract = new ethers.Contract(
    tokenSaleContractConfig.smartContract as string,
    abiTokenSale as any,
    jsonRPCProvider
  );
  
  const sold = await tokenSaleContract.tokensSold();
  
  return sold.toString();
};

const getTokenSaleActive = async (networkId: number) => {
 
  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const tokenSaleContract = new ethers.Contract(
    tokenSaleContractConfig.smartContract as string,
    abiTokenSale as any,
    jsonRPCProvider
  );
  const isActive = await tokenSaleContract.saleActive();
  
  return isActive;
};

const getTokenSaleBalance = async (address: string, networkId: number) => {

  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const tokenSaleContract = new ethers.Contract(
    tokenSaleContractConfig.tokenAddress as string,
    abiErc20 as any,
    jsonRPCProvider
  );

  const balance = await tokenSaleContract.balanceOf(address);
  const decimals = await getTokenDecimals(
    tokenSaleContractConfig.tokenAddress as string,
    networkId
  );
  const balanceValue = ethers.FixedNumber.from(balance);
  let decimalDivisor = ethers.FixedNumber.from(10);
  for (let i = 0; i < decimals - 1; i++) {
    decimalDivisor = decimalDivisor.mulUnsafe(ethers.FixedNumber.from(10));
  }
  const balanceEthers = balanceValue.divUnsafe(decimalDivisor);
  return balanceEthers.toString();
};

const approveTokenSale = async (
  address: string,
  amount: number,
  collateralAddress: string,
  walletProvider: any
) => {
 
  const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
  const signer = ethersProvider.getSigner();
  const paymentTokenContract = new ethers.Contract(
    collateralAddress as string,
    abiErc20 as any,
    signer
  );

  const collateralDecimals = await getTokenDecimals(
    collateralAddress as string,
    tokenSaleContractConfig.chainId
  );

  const amountValue = convertNumberToBigNumberWithDecimals(
    amount,
    collateralDecimals
  );

  let isUSDT =
    collateralAddress === tokenSaleContractConfig.collaterals[1].address;
  
  if(isUSDT){
    const allowance = await paymentTokenContract.allowance(
      address,
      tokenSaleContractConfig.smartContract as string
    );
    if(allowance > 0){
      const approve0 = await paymentTokenContract.approve(
        tokenSaleContractConfig.smartContract as string,
        "0"
      );
      await approve0.wait();
    }
  }

  const approved = await paymentTokenContract.approve(
    tokenSaleContractConfig.smartContract as string,
    amountValue
  );
  const approvetsx = await approved.wait();
  return approvetsx;
};

const checkTokenSaleCollateralAllowance = async (
  address: string,
  collateralAddress: string,
  amount: number,
  networkId: number
) => {

  const jsonRPCProvider = getJsonRpcProvider(networkId);
  const decimals = await getTokenDecimals(collateralAddress, networkId);
  const amountValue = convertNumberToBigNumberWithDecimals(amount, decimals);
  const paymentTokenContract = new ethers.Contract(
    collateralAddress as string,
    abiErc20 as any,
    jsonRPCProvider
  );
  const allowance = await paymentTokenContract.allowance(
    address,
    tokenSaleContractConfig.smartContract as string
  );
  return allowance >= amountValue;
};

export {
  getTokenInfos,
  getTokenSymbol,
  approve,
  getPurchasedShares,
  isAllowedAmount,
  getPreviewSharesOut,
  getPreviewAssetsOut,
  getPreviewAssetsIn,
  getPreviewSharesIn,
  executeSwappingMethod,
  updateMerkleRoot,
  isWhiteListed,
  toggleWhitelisting,
  isSupportingSelling,
  getLbpGraphInput,
  getTokenDecimals,
  calculatePrice,
  getPoolRefundState,
  getTokenName,
  getTotalTokensToSell,
  getTokenSaleActive,
  getTokenSalePrice,
  getTokenSaleSold,
  buyTokenSale,
  getTokenSaleBalance,
  approveTokenSale,
  checkTokenSaleCollateralAllowance,
  tokenSaleContractConfig,
};
